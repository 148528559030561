@import "~bootstrap";
@import "/node_modules/owl.carousel/dist/assets/owl.carousel.min.css";
@import "font-awesome";
@import "toastr";

$bootstrap-icons-font-src: url("/assets/fonts/bootstrap-icons.woff2") format("woff2"), url("/assets/fonts/bootstrap-icons.woff") format("woff");
@import "~bootstrap-icons";
@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&display=swap');

@media  (min-width: 992px) {
    .navbar-expand-lg{
        .navbar-collapse{
            display: inline-flex !important;
        }
    }
}

.pagination{
    margin-top: 20px;
    .page-item{
        .page-link{
            border:none;
            color: #666666;
            font-weight: 700;
        }
        &.active{
            .page-link{
                background-color: transparent;
                border-color: transparent;
                color: #e89c04;
            }
        }
    }
}

.text-default{
    color: #054c72 !important;
}
.text-yellow{
    color: #ffc700 !important;
}
.text-orange{
    color: #ffa566 !important;
}

.text-bold{
    font-weight: bold;
}

.fs-11{
    font-size: 11px !important;
    line-height: 14px;
}

.fs-17{
    font-size: 17px !important;
}

.fs-20{
    font-size: 20px !important;
}

.fs-50{
    font-size: 50px !important;
}

.btn{
    padding: 13px 25px;
    border-radius: 25px;
    font-size: 17px;
    line-height: 20px;
    font-weight: 700;
    text-align: center;
}

.btn_login-top{
    background-color: #014f7375;
    border: 1px solid #fffde596;
    background-image: url(/assets/images/ico-login-menu.webp);
    background-repeat: no-repeat;
    background-position: 15px 15px;
    padding-left: 40px;
}

.btn-white{
    background-color: #fffde5;
    color: #014f73;
}

.btn-dark-transparent{
    background-color: transparent;
    color: #fffde5;
    border: 1px solid #fffde5;
}

.btn-yellow{
    background-color: #ffc700;
    color: #fffde5;
}

.btn-social2{
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: #ffc700;
    text-align: center;
    vertical-align: middle;
    color: #004d72;
    display: table-cell;
}

.badge{
    border-radius: 20px;
    font-size: 13px;
    line-height: 13px;
    color: #004e72be;
    font-weight: 400;
    padding: 13px 20px;
}

.badge-quadrado{
    border-radius: 8px;
    font-size: 13px;
    line-height: 13px;
    color: #004e72be;
    font-weight: 400;
    padding: 7px 20px;
}

.badge-salmao{
    background-color: #ffebcc;
}

.badge-laranja{
    background-color: #ff8a3c;
    color: #ffffff;
}

.badge-light-blue{
    background-color: #d7f7fc;
}

.badge-light-yellow{
    background-color: #fff2b7;
}

.badge-yellow{
    background-color: #ffc700;
    color: #FFFFFF;
}

.ico-para-empresa-laranja{
    width: 40px;
    height: 40px;
    display: inline-block;
    background-color: #ffa566;
    border-radius: 50%;
    background-image: url(/assets/images/ico-para-empresa.webp);
    background-repeat: no-repeat;
    background-position: center center;
}

.ico-para-empresa-azul{
    width: 40px;
    height: 40px;
    display: inline-block;
    background-color: #0178b1;
    border-radius: 50%;
    background-image: url(/assets/images/ico-para-empresa.webp);
    background-repeat: no-repeat;
    background-position: center center;
}

.ico-para-colaborador-amarelo{
    width: 40px;
    height: 40px;
    display: inline-block;
    background-color: #ffc901;
    border-radius: 50%;
    background-image: url(/assets/images/ico-para-colaborador.webp);
    background-repeat: no-repeat;
    background-position: center center;
}

.ico-check{
    padding-left: 50px;
    background-image: url(/assets/images/ico-check-btn.webp);
    background-repeat: no-repeat;
    background-position: 25px 15px;
}

.ico-chat{
    padding-left: 50px;
    background-image: url(/assets/images/ico-chat.webp);
    background-repeat: no-repeat;
    background-position: 25px 15px;
}

.ico-creditos-para-viajar{
    background-image: url(/assets/images/ico-creditos-para-viajar.webp);
    background-repeat: no-repeat;
}
.ico-cash-do-cash{
    background-image: url(/assets/images/ico-cash-do-cash.webp);
    background-repeat: no-repeat;
}
.ico-nao-expira{
    background-image: url(/assets/images/ico-nao-expira.webp);
    background-repeat: no-repeat;
}
.ico-zarparcard{
    background-image: url(/assets/images/ico-zarparcard.webp);
    background-repeat: no-repeat;
}

.ico-search{
    position: relative;
    &::after{
        font: normal normal normal 15px / 1 FontAwesome;
        content: "\f002";
        color: #ffc700;
        position: absolute;
        right: 17px;
        top: 17px;
        transform: rotate(90deg);
    }
}

.align-middle{
    display: flex;
    align-items: center;
    height: 100%;
}

img.ico-home-fidelidade{
    width: 70px !important;
    height: 70px !important;
}

.hs-has-sub-menu{
    position: relative !important;
}
.hs-sub-menu{
    border: 5px solid #ffffff7a;
}

body{
    font-family: "Hanken Grotesk";
}
.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.6rem;
    padding-left: 1.6rem;
}
header{
    padding: 38px 0px !important;
    font-size: 15px;
    line-height: 18px;
    &.navbar{
        &.navbar-absolute-top{
            position: fixed;
            width: 100%;
            z-index: 991;
        }
    }
    &.header-nav-light{
        padding: 24px 0px !important;
        color: #fffde5;
        @media  (max-width: 991px) {
            .navbar-nav{
                background-color: #FFFFFF;
                color: #014f73;
                padding: 20px 0px;
                border-radius: 15px;
                a{
                    color: #014f73;
                    &:hover{
                        color: #014f73e8 !important;
                    }
                    &.show{
                        color: #014f73e8 !important;
                        &::after{
                            color: #014f73e8 !important;
                        }
                    }
                }
                li.text-yellow{
                    display: none;
                }
                .btn_login-top{
                    background-color: #FFFFFF;
                    border: 1px solid #e9efea;
                    background-image: url(/assets/images/ico-login-menu-yellow.webp);
                    margin-bottom: 15px;
                    margin-top: 10px;
                }
                .btn-yellow{
                    margin-left: 1.5rem;
                }
            }
        }
        a{
            color: #fffde5;
            font-size: 15px;
            font-weight: bold !important;
            &.show{
                color: #ffffff !important;
                &::after{
                    color: #FFFFFF;
                }
            }
            &.active{
                color: #ffffff !important;
            }
            &:hover{
                color: #ffffff !important;
            }
            &.dropdown-item {
                color: #054e72;
                padding-top: 15px;
                padding-bottom: 15px;
                &:hover{
                    color: #054e72e1 !important;
                    background-color: initial;
                }
                &:not(:last-child)::after{
                    content: '';
                    width: 100%;
                    display: block;
                    height: 1px;
                    background-color: #054e7260;
                    margin-top: 20px;
                }
            }
        }
    }
    &.navbar-relative-top,
    &.header-nav-default{
        background-color: #FFFFFF;
        color: #054c72;
        .navbar-toggler{
            border-color: #014f73 !important;
            .navbar-toggler-default,
            .navbar-toggler-toggled{
                color: #014f73 !important;
            }
        }
        a{
            color: #054c72;
            font-size: 14.8px;
            &.show{
                color: #054c72c7 !important;
                &::after{
                    color: #054c72c7;
                }
            }
            &.active{
                color: #054c72c7 !important;
            }
            &:hover{
                color: #054c72c7 !important;
            }
            &.btn-yellow{
                color: #fffde5;
            }
            &.btn_login-top{
                background-color: #FFFFFF;
                border: 1px solid #e9efea;
                background-image: url(/assets/images/ico-login-menu-yellow.webp);
            }
        }
    }
    nav{
        .btn{
            margin-top: -5px;
        }
        &.navbar-nav-wrap{
            width: 100%;
            .navbar-toggler{
                float: right;
                border-color: #FFFFFF;
                @media  (max-width: 767px) {
                    border-color: #004d72;
                }
                &:focus{
                    box-shadow:none;
                }
                .navbar-toggler-default{
                    display: none;
                    color: #FFFFFF;
                    @media  (max-width: 767px) {
                        color: #004d72;
                    }
                }
                .navbar-toggler-toggled{
                    display: inline-block;
                    color: #FFFFFF;
                    @media  (max-width: 767px) {
                        color: #004d72;
                    }
                }
                &.collapsed{
                    .navbar-toggler-default{
                        display: inline-block;
                    }
                    .navbar-toggler-toggled{
                        display: none;
                    }
                }
            }
        }
    }
}
.banner-main{
    color: #fffde5;
    width: 100%;
    overflow: hidden;
    @media  (max-width: 991px) {
        .owl-item{
            text-align: right;
        }
    }
    &::before{
        content: '';
        background-image: url(/assets/images/tracejado2-banner.webp);
        background-repeat: no-repeat;
        position: absolute;
        left: 45.5%;
        top: 293px;
        width: 225px;
        height: 690px;
        z-index: 99;
        @media (max-width: 1199px) {
            display: none;
        }
    }
    .owl-dots{
        display: none;
    }
    .main-banner-item{
        
        ul{
            list-style: none;
            margin-bottom: 0px;
            li{
                display: inline-block;
                margin-left: 25px;
                max-width: 255px;
                text-align: left;
                padding-right: 15px;
                margin-bottom: 10px;
                img{
                    max-width: 32px;
                    float: left;
                    margin: 10px 10px 0px 0px;
                }
            }
        }
    }
    .banner-main-item{
        background-color: #ffc700;
        min-height: 675px;
        .owl-item{
            &.active{
                .banner-main-left{
                    background-color: #ffc700;
                }
            }
        }
        .row{
            min-height: 675px;
        }
    }
    .banner-main-content{
        padding-top: 150px;
        @media (min-width: 768px) {
            width: 340px;
            float: right;
        }
        @media (min-width: 992px) {
            width: 460px;
            float: right;
        }
        @media (min-width: 1200px) {
            width: 550px;
            float: right;
        }
        @media (min-width: 1400px) {
            width: 640px;
            float: right;
        }
        @media (max-width: 767px) {
            max-width: 540px;
            align-items: center;
            display: block;
            flex-wrap: inherit;
            justify-content: space-between;
            width: 100%;
            padding-right: calc(var(--bs-gutter-x)* 0.5);
            padding-left: calc(var(--bs-gutter-x)* 0.5);
            margin-right: auto;
            margin-left: auto;
        }
    }
    
    
    h2{
        font-size: 50px;
        line-height: 65px;
        font-weight: 900;
        padding: 25px 0px 20px 0px;
        @media  (max-width: 540px) {
            font-size: 35px;
            line-height: 44px;
        }
    }
    .summary{
        font-size: 17px;
        line-height: 27px;
        font-weight: 400;
    }
    .banner-main-right{
        background-color: #014f73;
        padding: 0;
        position: relative;
        @media  (max-width: 540px) {
            display: none;
        }
        &::before{
            content: '';
            width: 179px;
            height: 273px;
            position: absolute;
            left: 0;
            top: 30%;
            background-image: url(/assets/images/banner-divide.png);
            background-repeat: no-repeat;
            background-position: left center;
            z-index: 9;
        }
    }
}

.quem-home{
    width: 100%;
    color: #054c72;
    background-color: #fffde5;
    .quem-somos-bg{
        background: linear-gradient(to right, #fffde5 50.3%, #014f73 49.7%);
        @media  (max-width: 991px) {
            background: transparent;
        }
        .quem-home-left{
            padding-top: 100px;
            padding-bottom: 100px;
        }
        .quem-home-right{
            background-color: #fffde5;
            background-image: url(/assets/images/quem-somos-home-bg.webp);
            background-repeat: repeat-y;
            background-position: left top;
            position: relative;
            @media  (max-width: 991px) {
                display: none;
            }
            h3{
                position: absolute;
                bottom: 0px;
                left: 30%;
                font-size: 27px;
                line-height: 41px;
                color: #fffde5;
                font-weight: 300;
                text-align: right;
            }
        }
    }
    .quem-home-left, .quem-home-right{
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.section-fidelidade{
    background: linear-gradient(to left, #ffc700 50%, #014f73 50%);
    @media  (max-width: 575px) {
        background: #014f73;
    }
    .efeito-fidelidade{
        background-image: url(/assets/images/efeito-fidelidade.webp);
        background-repeat: no-repeat;
        background-position: center right;
    }
    h1{
        font-size: 40px;
        line-height: 52px;
        color: #fffde5;
        position: relative;
        padding-top: 80px;
        padding-bottom: 30px;
        font-weight: 300;
        div{
            position: relative;
            &::before{
                content: '';
                display: block;
                position: absolute;
                width: 35px;
                height: 7px;
                background-color: #ffc700;
                border-radius: 5px;
                top: -18px;
                transform: rotate(-32deg);
            }
        }
    }
    .fidelidade2-right{
        background-image: url(/assets/images/z-bg-fidelidade.webp);
        background-repeat: no-repeat;
        background-position: top left;
    }
    .fidelidade-left{
        padding-right: 18%;
        position: relative;
        font-size: 17px;
        line-height: 27px;
        color: #fffde5;
        font-weight: 400;
        &::after{
            content:'';
            width: 655px;
            height: 730px;
            position: absolute;
            right: -310px;
            bottom: 0px;
            background-image: url(/assets/images/de-ferias-zarpar.webp);
            background-repeat: no-repeat;
            @media  (max-width: 991px) {
                display: none;
            }
        }
        h2{
            font-size: 40px;
            line-height: 52px;
            color: #fffde5;
            padding-left: 110px;
            position: relative;
            padding-top: 58px;
            &::before{
                content:'';
                width: 75px;
                height: 220px;
                position: absolute;
                left: 0;
                top: -70px;
                background-image: url(/assets/images/z.webp);
                background-repeat: no-repeat;
            }
        }
        ul{
            list-style: none;
            padding: 0px;
            margin-top: 60px;
            li{
                a{
                    display: inline-block;
                    width: 100%;
                    padding: 45px 0px 45px 55px;
                    font-size: 20px;
                    line-height: 24px;
                    color: #fffde5;
                    font-weight: 400;
                    text-decoration: none;
                    &:hover{
                        color: #ffc700;
                    }
                    &:focus{
                        color: #ffc700;
                    }
                    border-top: 1px solid #0178b1;
                    background-position: left center;
                }
            }
        }
    }
}

#fidelidadeCarousel{
    .owl-item{
        .item{
            padding: 300px 0px 50px 250px;
            font-size: 17px;
            line-height: 27px;
            color: #004e72;
            font-weight: 500;
            @media  (max-width: 991px) {
                padding: 300px 0px 50px 40px;
            }
            @media  (max-width: 575px) {
                padding: 30px 0px 50px 0px;
                color: #FFFFFF;
                &.text-end{
                    text-align: center !important;
                }
            }
        }
    }
}


section.whatsapphome{
    padding: 60px 0px;
    border-bottom: 1.5px solid #ccdce3;
    .whatsapphome-left{
        font-size: 15px;
        color: #004e72;
        font-weight: 400;
        h3{
            font-size: 30px;
            line-height: 36px;
            color: #054c72;
            font-weight: 300;
        }
    }
    .whatsapphome-right{
        strong{
            font-size: 13px;
            line-height: 16px;
            color: #054c72;
            font-weight: 600;
        }
        p{
            font-size: 15px;
            line-height: 24px;
            color: #054c72;
            font-weight: 400;
        }
        .whatsapp_link{
            font-size: 15px;
            line-height: 24px;
            color: #054c72;
            font-weight: 600;
            background-image: url(/assets/images/ico-whatsapp.webp);
            background-repeat: no-repeat;
            padding-left: 30px;
            text-decoration: none;
            padding-bottom: 6px;
        }
    }
}

section.home-partners{
    .owl-nav { 
        display: block !important;
        button.owl-prev{
            position: absolute;
            top: 35px;
            color: #ffc700;
            font-size: 22px;
            font-weight: bold;
        }
        button.owl-next{
            position: absolute;
            top: 35px;
            right: 0px;
            color: #ffc700;
            font-size: 22px;
            font-weight: bold;
        }
    }
    .owl-stage-outer{
        padding: 20px 0px;
        .owl-stage{
            .owl-item{
                .item{
                    text-align: center;
                    img{
                        display: inline-block;
                        width: initial;
                        max-height: 60px;
                    }
                }
            }
        }
    }
}

.section-vantagens{
    padding: 60px 0px;
    background-color: #fffde5;
    h2{
        font-size: 40px;
        line-height: 48px;
        color: #054c72;
        font-weight: 400;
    }
    .vantagens-nav{
        .nav-tabs{
            width: 100%;
            text-align: center;
            display: inline-table;
            margin-top: 40px;
            .nav-item{
                display: inline-block;
                border-bottom: 1px solid #ccdace;
                a{
                    &.nav-link{
                        font-size: 17px;
                        line-height: 20px;
                        color: #054c72;
                        font-weight: 700;
                        padding-left: 0px;
                        padding-right: 75px;
                        padding-bottom: 25px;
                        background-color: transparent;
                        background-repeat: no-repeat;
                        background-position-x: 25px;
                        display: inline-flex;
                        &.active{
                            font-size: 17px;
                            line-height: 20px;
                            color: #054c72;
                            font-weight: 700;
                            background-color: transparent;
                        }
                        &.nav-para-colaborador{
                            &.active,
                            &:hover{
                                border-top-color: transparent;
                                border-left-color: transparent;
                                border-right-color: transparent;
                                border-bottom-color: #ffc700;
                                border-bottom-width: 2px;
                            }
                        }
                        &.nav-para-empresa{
                            &.active,
                            &:hover{
                                border-top-color: transparent;
                                border-left-color: transparent;
                                border-right-color: transparent;
                                border-bottom-color: #0178b1;
                                border-bottom-width: 2px;
                            }
                        }
                    }
                }
            }
        }
    }
    .tab-content{
        padding-top: 60px;
        .item-vantagem{
            margin-bottom: 30px;
            picture{
                display: block;
                position: relative;
                height: 170px;
                background-color: #ffa566;
                background-image: url(/assets/images/efeito-beneficios.webp);
                background-repeat: no-repeat;
                background-position: top 10px right 10px;
                border-radius: 24px 24px 0px 0px;
                .ico-beneficio{
                    width: 50px;
                    height: 50px;
                    border-radius: 8px;
                    background-color: #ffbe93;
                    background-size: 25px;
                    background-position: center center;
                    background-repeat: no-repeat;
                    position: absolute;
                    bottom: -25px;
                    left: 20px;
                }
                img{
                    max-height: 190px;
                    position: absolute;
                    bottom: 0px;
                    left: 0px;
                    right: 0px;
                    margin: 0 auto;
                }
            }
            .content-vantagens{
                font-size: 15px;
                line-height: 24px;
                color: #054c72;
                font-weight: 400;
                background-color: #FFFFFF;
                padding: 30px;
                border-radius: 0px 0px 24px 24px;
                min-height: 220px;
                a{
                    text-decoration: none;
                    font-size: 15px;
                    color: #054c72;
                }
                h2{
                    font-size: 17px;
                    line-height: 29px;
                    color: #054c72;
                    font-weight: 700;
                    padding-top: 10px;
                    a{  
                        color: #054c72;
                        font-size: 17px;
                        &:hover{
                            opacity: 0.8;
                        }
                    }
                }
            }
        }
    }
}

.section-blog-home{
    padding: 60px 0px;
    background-color: #014f73;
    position: relative;
    .container{
        position: relative;
    }
    .title{
        padding-bottom: 30px;
        h6{
            font-size: 13px;
            line-height: 16px;
            color: #ffc600;
            font-weight: 600;
        }
        h2{
            font-size: 40px;
            line-height: 48px;
            color: #fffde5;
            font-weight: 400;
        }
    }
    .slider-blog{
        &.owl-carousel{
            position: initial;
            .owl-stage-outer{
                position: initial;
            }
        }
        .owl-nav{
            button{
                position: absolute;
                color: #ffc700;
                font-size: 35px;
                padding: 0px 22px !important;
                border-radius: 8px;
                top: 10px;
                background-color: #34728a;
                &.owl-prev{
                    right: 65px;
                }
                &.owl-next{
                    right: 0px;
                }
                &:hover,&:focus{
                    background-color: #fffde5;
                }
                @media  (max-width: 767px) {
                    top: -30px;
                    &.owl-prev{
                        right: 75px;
                    }
                    &.owl-next{
                        right: 10px;
                    }
                }
            }
        }
        .item-slider-blog{
            padding: 0px 15px;
            picture{
                display: block;
                height: 240px;
                background-repeat: no-repeat;
                border-radius: 24px 24px 0px 0px;
                background-repeat: no-repeat;
                position: relative;
                background-size: cover;
                span{
                    display: block;
                    padding: 6px 15px;
                    font-size: 13px;
                    line-height: 22px;
                    color: #004e72;
                    font-weight: 500;
                    border-radius: 8px;
                    background-color: #ffb6a6;
                    position: absolute;
                    bottom: -16px;
                    left: 20px;
                }
            }
            .content-blog-home{
                background-color: #fffde5;
                min-height: 230px;
                border-radius: 0px 0px 24px 24px;
                padding: 40px 20px;
                .date{
                    font-size: 13px;
                    line-height: 2px;
                    color: #004d72;
                    font-weight: 500;
                    opacity: 0.702;
                    display: block;
                    padding-bottom: 20px;
                    padding-top: 10px;
                }
                .title{
                    h3,a{
                        font-size: 17px;
                        line-height: 27px;
                        color: #054c72;
                        font-weight: 700;
                        text-decoration: none;
                    }
                }
            }
        }
        
    }
}

.section-call-to-action{
    padding: 60px 0px;
    background-color: #014f73;
    position: relative;
    .container{
        position: relative;
    }
    .title{
        padding-bottom: 30px;
        h6{
            font-size: 13px;
            line-height: 16px;
            color: #fffde5;
            font-weight: 500;
            padding-bottom: 20px;
        }
        h2{
            font-size: 40px;
            line-height: 48px;
            color: #ffc600;
            font-weight: 400;
        }
    }
}

.section-footer_login{
    background-color: #fffde5;
    padding: 40px 0px;
    font-size: 17px;
    line-height: 26px;
    color: #004e72;
    font-weight: 400;
    border-bottom: 1.5px solid #ccdce3;
    @media  (max-width: 991px) {
        .text-logo{
            display: none;
        }
    }
    .btn_login-top{
        background-color: #FFFFFF;
        border: 1px solid #e9efea;
        background-image: url(/assets/images/ico-login-menu-yellow.webp);
    }
    .footer-btns{
        text-align: right;
        .navbar-nav{
            display: block;
            li{
                display: inline-block;
                @media  (max-width: 991px) {
                    &.text-yellow{
                        display: none;
                    }
                }
            }
        }
    }
    
}

footer.footer{
    padding: 40px 0px;
    h4{
        font-size: 17px;
        line-height: 26px;
        color: #004d72;
        font-weight: 700;
        padding-bottom: 20px;
    }
    ul{
        margin: 0;
        padding: 0;
        list-style: none;
    }
    a{
        font-size: 13px;
        line-height: 29px;
        color: #004c72;
        font-weight: 500;
        text-decoration: none;
        &:hover{
            opacity: 0.800;
        }
    }
    .btn-white{
        line-height: 18px;
        background-color: #FFFFFF;
        border: 1px solid #ccdce3;
        font-weight: 700;
        font-size: 17px;
        @media  (max-width: 991px) {
            font-size: 13px;
            line-height: 15px;
        }
    }
    .copyright{
        font-size: 13px;
        line-height: 26px;
        color: #004d72;
        font-weight: 600;
        padding-top: 30px;
        margin-top: 30px;
        border-top: 1.5px solid #ccdce3;
    }
}


.section-about-main{
    color: #004d72;
    width: 100%;
    overflow: hidden;
    background-color: #fffde5;
    background: linear-gradient(to bottom, #ffc700 90px, #fffde5 0px);
    
    &::before{
        content: '';
        background-image: url(/assets/images/tracejado2-banner.webp);
        background-repeat: no-repeat;
        position: absolute;
        left: 45.5%;
        top: 293px;
        width: 225px;
        height: 690px;
        z-index: 99;
        @media (max-width: 1199px) {
            display: none;
        }
    }
    .about-main-item{
        background: linear-gradient(to right, transparent 60%, #014f73 40%);
        @media  (max-width: 767px) {
            background: #fffde5 !important;
            text-align: center;
        }
        min-height: 675px;
        .row{
            min-height: 675px;
        }
        ul{
            list-style: none;
            margin-bottom: 0px;
            li{
                display: inline-block;
                margin-left: 25px;
                max-width: 255px;
                text-align: left;
                padding-right: 15px;
                margin-bottom: 10px;
                img{
                    max-width: 32px;
                    float: left;
                    margin: 10px 10px 0px 0px;
                }
            }
        }
    }
    
    .about-main-left{
        padding-top: 150px;
        img{
            max-width: 80%;
        }
        h1{
            font-size: 50px;
            line-height: 65px;
            color: #004d72;
            font-weight: bold;
            padding: 25px 0px 20px 0px;
        }
        .description{
            font-size: 15px;
            line-height: 24px;
            color: #004c72;
            font-weight: 400;
        }
    }
    .about-main-right{
        background-color: #014f73;
        padding: 200px 0px 0px 0px;
        position: relative;
        font-size: 27px;
        line-height: 41px;
        color: #fffde5;
        font-weight: 300;
        @media  (max-width: 767px) {
            padding: 200px 20px 0px 0px;
        }
        @media  (max-width: 991px) {
            font-size: 25px;
        }
        &::before{
            content: '';
            width: 179px;
            height: 273px;
            position: absolute;
            left: 0;
            top: 30%;
            background-image: url(/assets/images/about-divide.png);
            background-repeat: no-repeat;
            background-position: left center;
            z-index: 9;
        }
    }
}

.section-about-values{
    color: #fffde5;
    background-image: url(/assets/images/about-propositos.webp);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: auto 100%;
    .about-values-item{
        position: relative;
        background: linear-gradient(to right, #014f73 50%, transparent 50%);
        @media  (max-width: 991px) {
            background-color: #014f73 !important;
        }
        
    }
    .about-values-right{
        position: relative;
        &::before{
            content: '';
            width: 40px;
            height: 100%;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            background-image: url(/assets/images/z-divide-about-values.webp);
            background-repeat: repeat-y;
        }
    }
    .about-values-left{
        padding: 40px 15px 40px 15px;
        background-color: #014f73;
        font-size: 15px;
        line-height: 18px;
        h2{
            font-size: 50px;
            line-height: 65px;
            color: #fffde5;
            font-weight: 300;
        }
        ul{
            list-style: none;
            padding: 0px;
            li{
                margin-bottom: 40px;
                padding-left: 50px;
                background-repeat: no-repeat;
                &.ico-about-praticidade{
                    background-image: url(/assets/images/ico-about-praticidade.webp);
                }
                &.ico-about-parceria{
                    background-image: url(/assets/images/ico-about-parceria.webp);
                }
                &.ico-about-confianca{
                    background-image: url(/assets/images/ico-about-confianca.webp);
                }
                &.ico-about-encantamento{
                    background-image: url(/assets/images/ico-about-encantamento.webp);
                }
            }
        }
    }

    .about-propositos{
        border: 1px solid #0178b1;
        border-radius: 24px;
        padding: 30px 20px;
        margin-top: 20px;
        font-size: 15px;
        line-height: 24px;
        color: #fffde5;
        h3{
            font-size: 20px;
            line-height: 24px;
            font-weight: 700;
        }
    }
}

.section-about-team{
    background: linear-gradient(to bottom, #fffde5 50%, #014f73 50%);
    padding: 40px 0px;
    h2.title1{
        font-size: 40px;
        line-height: 48px;
        color: #054c72;
        font-weight: 400;
    }
    .description1{
        font-size: 15px;
        line-height: 24px;
        color: #004c72e0;
        font-weight: 400;
    }
    h2.title2{
        font-size: 40px;
        line-height: 48px;
        color: #ffc600;
        font-weight: 400;
    }
    .subtitle2{
        font-size: 13px;
        line-height: 16px;
        color: #fffde5;
        font-weight: 500;
        display: block;
        padding-bottom: 20px;
    }
    .slider-about-team{
        position: relative;
        .owl-stage-outer{
            max-height: 640px;
            border-radius: 24px;
            overflow: hidden;
        }
        .owl-nav{
            button{
                position: absolute;
                color: #ffc700;
                font-size: 35px;
                padding: 0px 22px !important;
                border-radius: 8px;
                bottom: 40px;
                background-color: #ffffff71;
                &:hover,&:focus{
                    background-color: #fffde5;
                }
            }
            button.owl-prev {
                right: 85px;
            }
            button.owl-next {
                right: 20px;
            }
        }
        
    }
}

.section-fidelidade-credito{
    padding: 90px 0px;
    background: #fffde5;
    background-image: url(/assets/images/bg-fidelidade-credito.webp);
    background-repeat:repeat-y;
    background-position: top -10px right -30px;
    color: #014f73;
    h2{
        font-size: 40px;
        line-height: 60px;
        color: #004d72;
        font-weight: 400;
        padding: 15px 0px;
    }
    .fidelidade-credito-vantagens{
        padding-top: 40px;
        padding-bottom: 80px;
        .item{
            width: 100%;
            height: 100%;
            border-radius: 24px;
            background-color: #ffffff;
            font-size: 16px;
            line-height: 24px;
            min-height: 170px;
            color: #014f73;
            font-weight: 800;
            padding: 25px;
            h3{
                font-size: 16px;
                line-height: 24px;
                color: #014f73;
                font-weight: 800;
            }
            .ico-fidelidade{
                background-repeat: no-repeat;
                width: 80px;
                height: 40px;
                display: block;
                margin-bottom: 20px;
            }
            .ico-fidelidade-cashback{
                background-image: url(/assets/images/icon-fidelidade-cashback.webp);
            }
            .ico-fidelidade-carencia-zero{
                background-image: url(/assets/images/icon-fidelidade-carencia.webp);
            }
            .ico-fidelidade-vale-ferias{
                background-image: url(/assets/images/icon-fidelidade-ferias.webp);
            }
            .ico-fidelidade-pague-menos{
                background-image: url(/assets/images/icon-fidelidade-pague-menos.webp);
            }
            .ico-fidelidade-creditos{
                background-image: url(/assets/images/icon-fidelidade-cartao-credito.webp);
            }
            .ico-fidelidade-simples{
                background-image: url(/assets/images/icon-fidelidade-sem-complicacao.webp);
            }
        }
    }
}

.section-fidelidade-servicos{
    background-color: #014f73;
    color: #fffde5;
    padding-bottom: 30px;
    .nav{
        --bs-nav-link-padding-x:initial;
        --bs-nav-link-padding-y:initial;
    }
    .tab-pane{
        position: relative;
        h3{
            font-size: 30px;
            line-height: 36px;
            color: #ffc700;
            font-weight: 400;
        }
    }
    .destinos-sliders{
        &.owl-carousel{
            position: initial;
            .owl-stage-outer{
                position: initial;
            }
        }
        .owl-nav{
            button{
                position: absolute;
                color: #ffc700;
                font-size: 35px;
                padding: 0px 22px !important;
                border-radius: 8px;
                top: 20px;
                background-color: #34728a;
                &.owl-prev{
                    right: 65px;
                }
                &.owl-next{
                    right: 0px;
                }
                &:hover,&:focus{
                    background-color: #fffde5;
                }
                @media  (max-width: 767px) {
                    top: 82px;
                    &.owl-prev{
                        right: 75px;
                    }
                    &.owl-next{
                        right: 10px;
                    }
                }
            }
        }
        .item-destinos-sliders{
            padding: 0px 15px;
            picture{
                display: block;
                height: 290px;
                background-repeat: no-repeat;
                border-radius: 24px 24px 0px 0px;
                background-repeat: no-repeat;
                position: relative;
                span{
                    display: block;
                    padding: 6px 15px;
                    font-size: 13px;
                    line-height: 22px;
                    color: #004e72;
                    font-weight: 500;
                    border-radius: 8px;
                    background-color: #ffa566;
                    position: absolute;
                    bottom: -16px;
                    left: 20px;
                    text-transform: uppercase;
                }
            }
            .content-destinos-sliders{
                background-color: #fffde5;
                min-height: 120px;
                border-radius: 0px 0px 24px 24px;
                padding: 40px 20px;
                font-size: 15px;
                line-height: 24px;
                color: #054c72;
                font-weight: 500;
            }
        }
        
    }
    h2{
        font-size: 40px;
        line-height: 52px;
        color: #fffde5;
        padding-left: 110px;
        position: relative;
        padding-top: 58px;
        &::before{
            content: "";
            width: 75px;
            height: 220px;
            position: absolute;
            left: 0;
            top: -70px;
            background-image: url(/assets/images/z.webp);
            background-repeat: no-repeat;
        }
    }
    .fidelidade-servicos-categorias{
        border-radius: 16px;
        overflow: hidden;
        position: relative;
        width: 100%;
        a{
            text-decoration: none;
            color: #014f73;
            font-weight: 600;
            line-height: 16px;
            text-transform: uppercase;
            
            picture{
                width: 100%;
                height: 160px;
                display: block;
                position: relative;
                img{
                    max-width: 100%;
                    position: absolute;
                    bottom: 10px;
                }
            }
            h3{
                font-size: 13px;
                font-weight: 600;
                background-color: #fffde5;
                color: #014f73;
                position: absolute;
                bottom: 0px;
                text-align: center;
                display: block;
                margin: 0px;
                padding: 12px 0px;
                width: 100%;
                &:hover{
                    background-color: #ffa566;
                    color: #fffde5;
                }
            }
        }
    }
}

.banner-beneficios{
    width: 100%;
    min-height: 700px;
    background: linear-gradient(to left, transparent 70%, #0178b1 30%);
    .owl-dots{
        display: none;
    }
    .banner-beneficios-bg{
        background-repeat: no-repeat;
        background-position: top 0px right 0px;
    }
    .banner-beneficios-left{
        background-image: url(/assets/images/banner-beneficios-bg.webp);
        background-repeat: repeat-y;
        background-position: top -40px right 0px;
        min-height: 700px;
        color: #fffde5;
        padding: 60px 120px 10px 0px;
        h1{
            font-size: 40px;
            line-height: 52px;
            font-weight: 300;
            position: relative;
            padding-top: 15px;
            &::before{
                content: "";
                display: block;
                position: absolute;
                width: 35px;
                height: 7px;
                background-color: #ffc700;
                border-radius: 5px;
                top: -18px;
                transform: rotate(-32deg);
            }
            strong{
                font-weight: bold;
            }
        }
        p{
            font-size: 19px;
            line-height: 38px;
            font-weight: 400;
        }
        .subtitle{
            display: inline-flex;
            margin-bottom: 70px;
            .name{
                border: 1px solid #fffde5;
                display: inline-block;
                border-radius: 20px;
                font-size: 13px;
                line-height: 16px;
                color: #fffde5;
                font-weight: 500;
                padding: 10px 22px;
                text-transform: uppercase;
            }
        }
    }
}

.section-banner-vale-viagem{
    background-color: #fffde5;
    padding-top: 10px;
    border-top: 1px solid #ebebeb;
    .banner-vale-viagem{
        .banner-vale-viagem-item{
            min-height: 520px;
            background-repeat: no-repeat;
            background-size: 100% 520px;
            padding-right: 53%;
            padding-left: 25px;
            padding-top: 150px;
            @media  (max-width: 540px) {
                padding-top: 100px;
            }
            @media  (max-width: 1399px) {
                background-size: auto 520px;
            }
            @media  (max-width: 991px) {
                padding-right: 30%;
            }
            @media  (max-width: 767px) {
                padding-right: 25px;
            }
            h2{
                font-size: 35px;
                @media  (max-width: 540px) {
                    font-size: 30px;
                }
                line-height: 56px;
                color: #fffde5;
                font-weight: 400;
                position: relative;
                padding-top: 15px;
                strong{
                    font-weight: bold;
                }
                &::before{
                    content: "";
                    display: block;
                    position: absolute;
                    width: 35px;
                    height: 7px;
                    background-color: #ffc700;
                    border-radius: 5px;
                    top: -18px;
                    transform: rotate(-32deg);
                }
            }
        }
    }
    
}

.section-main-vale-viagem{
    padding: 60px 0px;
    background-color: #fffde5;
    font-size: 15px;
    line-height: 24px;
    color: #004c72ef;
    img{
        max-width: 100%;
    }
    h1{
        font-size: 40px;
        line-height: 48px;
        color: #004c72ef;
        font-weight: 400;
        strong{
            font-weight: bold;
        }
    }
    h3{
        font-size: 17px;
        line-height: 29px;
        font-weight: 700;
    }
    .main-vale-viagem-item-content{
        display: flex;
        align-items: center;
        height: 100%;
        .btn-white {
            line-height: 18px;
            background-color: transparent;
            border: 1px solid #ccdce3;
            font-weight: 700;
            font-size: 17px;
        }
    }
}

.section-when-vale-viagem{
    background-color: #014f73;
    padding: 60px 0px 80px 0px;
    color: #fffde5;
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    h2{
        font-size: 40px;
        line-height: 48px;
        color: #ffffff;
        font-weight: 400;
        strong{
            font-weight: bold;
        }
    }
    .tab-content{
        picture{
            display: block;
            position: relative;
            border-radius: 24px 24px 0px 0px;
            overflow: hidden;
            height: 180px;
            img{
                width: 100%;
            }
            &::before{
                content: '';
                display: block;
                position: absolute;
                width: 52px;
                height: 50px;
                background-image: url(/assets/images/efeito-beneficios.webp);
                background-repeat: no-repeat;
                right: 10px;
                top: 10px;
            }
        }
        .ico-beneficio {
            width: 50px;
            height: 50px;
            border-radius: 8px;
            background-position: center center;
            background-repeat: no-repeat;
            position: absolute;
            top: 155px;
            left: 35px;
        }
        .content-when-vale-viagem{
            min-height: 210px;
            border-radius: 0px 0px 24px 24px;
            background-color: #ffffff;
            color: #054c72;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            padding: 40px 25px 0px 25px;
            a{
                color: #054c72;
                text-decoration: none;
            }
            h2{
                font-size: 17px;
                line-height: 29px;
                color: #054c72;
                font-weight: 700;
            }
        }
    }
}

.section-vantagens-vale-viagem{
    background: linear-gradient(to left, #fffde5 50%, #014f73 50%);
    @media  (max-width: 991px) {
        background: #014f73;
    }
    .vantagens-left{
        padding-right: 10%;
        position: relative;
        font-size: 17px;
        line-height: 27px;
        color: #fffde5;
        font-weight: 400;
        &::after{
            content: "";
            width: 498px;
            height: 600px;
            position: absolute;
            right: -360px;
            bottom: 0px;
            background-image: url(/assets/images/vantagens-beneficios-colaborador.webp);
            background-repeat: no-repeat;
            @media  (max-width: 991px) {
                display: none;
            }
        }
        h2{
            font-size: 40px;
            line-height: 52px;
            color: #fffde5;
            padding-left: 110px;
            position: relative;
            padding-top: 58px;
            font-weight: 300;
            @media  (max-width: 540px) {
                font-size: 30px;
            }
            strong{
                font-weight: bold;
            }
            &::before{
                content: "";
                width: 75px;
                height: 220px;
                position: absolute;
                left: 0;
                top: -70px;
                background-image: url(/assets/images/z.webp);
                background-repeat: no-repeat;
            }
        }
        ul{
            list-style: none;
            padding: 0px;
            margin-top: 60px;
            display: inline-block;
            @media  (max-width: 991px) {
                display: block;
            }
            li{
                a{
                    display: inline-block;
                    width: 100%;
                    padding: 45px 20px 45px 0px;
                    font-size: 20px;
                    line-height: 24px;
                    color: #fffde5;
                    font-weight: 400;
                    text-decoration: none;
                    border-top: 1px solid #0178b1;
                    background-position: left center;
                    &:hover{
                        color: #ffc700;
                    }
                    &:focus{
                        color: #ffc700;
                    }
                }
            }
        }
    }
    .vantagens-right{
        .btn-white{
            line-height: 18px;
            background-color: transparent;
            border: 1px solid #ccdce3;
            font-weight: 700;
            font-size: 17px;
        }
        #fidelidadeCarousel{
            .owl-item{
                .item{
                    padding: 200px 0px 50px 250px;
                    @media  (max-width: 991px) {
                        color: #fffde5;
                        padding: 0;
                        text-align: center !important;
                        .btn-white{
                            color: #fffde5;
                        }
                    }
                }
            }
        }
    }
}

.section-depoimento{
    background: #014f73;
    padding: 60px 0px;
    color: #ffffff;
    .title{
        position: relative;
        h2{
            font-size: 40px;
            line-height: 48px;
            color: #ffffff;
            font-weight: 400;
            position: relative;
            padding: 40px 0px;
            strong{
                font-weight: bold;
            }
        }
    }
    .slider-depoimento{
        .item-slider-depoimento{
            .item-slider-depoimento-balao{
                background-color: #fffde5;
                width: 100%;
                min-height: 320px;
                border-radius: 15px;
                padding: 60px 80px;
                text-align: center;
                font-size: 15px;
                line-height: 27px;
                color: #004e72;
                font-weight: 400;
                position: relative;
                margin-bottom: 60px;
                .marca{
                    padding-bottom: 15px;
                    img{
                        max-height: 45px;
                        width: auto;
                        display: inline-block;
                    }
                }
                &::after{
                    content: '';
                    display: block;
                    position: absolute;
                    width: 93px;
                    height: 22px;
                    position: absolute;
                    bottom: -22px;
                    left: calc(50% - 46.5px);
                    background-image: url(/assets/images/ponta-balao.webp);
                    background-repeat: no-repeat;
                }
            }
            .item-slider-depoimento-cliente{
                text-align: center;
                .photo{
                    img{
                        width: 70px;
                        height: 70px;
                        border-radius: 35px;
                        display: inline-block;
                    }
                }
                .name{
                    font-size: 17px;
                    line-height: 20px;
                    color: #ffffff;
                    font-weight: 900;
                    padding: 10px 0px;
                }
                .cargo{
                    font-size: 15px;
                    line-height: 18px;
                    color: #ffffff;
                    font-weight: 400;
                }
            }
        }
        .owl-nav{
            button{
                position: absolute;
                color: #ffc700;
                font-size: 35px;
                padding: 0px 22px !important;
                border-radius: 8px;
                top: -100px;
                background-color: #34728a;
                &.owl-prev{
                    right: 65px;
                }
                &.owl-next{
                    right: 0px;
                }
                &:hover,&:focus{
                    background-color: #fffde5;
                }
                @media  (max-width: 767px) {
                    top: -50px;
                    &.owl-prev{
                        right: 75px;
                    }
                    &.owl-next{
                        right: 10px;
                    }
                }
            }
        }
    }
}

.section_blog_search{
    background-color: #fffde5;
    padding-top: 50px;
    padding-bottom: 60px;
    border-top: 1px solid #ebebeb;
    font-size: 15px;
    line-height: 24px;
    color: #054c72;
    font-weight: 400;
    .categories{
        padding: 0;
        list-style: none;
        li{
            display: inline-block;
            a{
                text-decoration: none;
                color: #004e72;
                padding: 5px 10px;
            }
        }
    }
    .time{
        font-size: 13px;
        line-height: 2px;
        color: #004e72;
        font-weight: 500;
        padding: 10px 0px 10px 0px;
        .fa{
            color: #ffc700;
            margin-right: 7px;
        }
    }
    h1{
        font-size: 35px;
        line-height: 42px;
        color: #004e72;
        font-weight: 700;
        position: relative;
        padding-top: 15px;
        &::before{
            content: "";
            display: block;
            position: absolute;
            width: 35px;
            height: 7px;
            background-color: #ffc700;
            border-radius: 5px;
            top: -18px;
            transform: rotate(-32deg);
        }
    }
    .owl-stage-outer{
        max-height: 640px;
        border-radius: 24px;
        overflow: hidden;
    }
    .owl-carousel{
        .owl-nav{
            button{
                position: absolute;
                width: 50px;
                height: 50px;
                color: #ffc700 !important;
                font-size: 35px !important;
                padding-left: 22px !important;
                padding-right: 22px !important;
                border-radius: 8px;
                bottom: 50px;
                background-color: #ffffff71 !important;
                &:hover,&:focus{
                    background-color: #fffde5;
                }
            }
            button.owl-prev {
                right: 95px;
            }
            button.owl-next {
                right: 30px;
            }
        }
    }
    .form-select{
        border: 2px solid #e0e0e0;
        border-radius: 14px;
        padding: 12px 20px;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffc700' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    }
    input[type="search"]{
        padding: 12px 20px;
        border-radius: 14px;
        background-color: #014f73;
        color: #FFFFFF;
        &::placeholder{
            color: #FFFFFF;
        }
    }
}

.section-new-post{
    background-color: #014f73;
    position: relative;
    padding-bottom: 60px;
    .container{
        position: relative;
    }
    .title{
        padding-bottom: 30px;
        h6{
            font-size: 13px;
            line-height: 16px;
            color: #ffc600;
            font-weight: 600;
        }
        h2{
            font-size: 40px;
            line-height: 48px;
            color: #fffde5;
            font-weight: 400;
            position: relative;
            padding-left: 110px;
            padding-top: 58px;
            strong{
                font-weight: bold;
            }
            &::before{
                content: "";
                width: 75px;
                height: 220px;
                position: absolute;
                left: 0;
                top: -70px;
                background-image: url(/assets/images/z.webp);
                background-repeat: no-repeat;
            }
        }
    }
    .slider-blog{
        &.owl-carousel{
            position: initial;
            .owl-stage-outer{
                position: initial;
            }
        }
        .owl-nav{
            button{
                position: absolute;
                color: #ffc700;
                font-size: 35px;
                padding: 0px 22px !important;
                border-radius: 8px;
                top: 80px;
                background-color: #34728a;
                &.owl-prev{
                    right: 65px;
                }
                &.owl-next{
                    right: 0px;
                }
                &:hover,&:focus{
                    background-color: #fffde5;
                }
                @media  (max-width: 767px) {
                    top: -30px;
                    &.owl-prev{
                        right: 75px;
                    }
                    &.owl-next{
                        right: 10px;
                    }
                }
            }
        }
        .item-slider-blog{
            padding: 0px 15px;
            picture{
                display: block;
                height: 240px;
                background-repeat: no-repeat;
                border-radius: 24px 24px 0px 0px;
                position: relative;
                background-size: cover;
                span{
                    display: block;
                    padding: 6px 15px;
                    font-size: 13px;
                    line-height: 22px;
                    color: #004e72;
                    font-weight: 500;
                    border-radius: 8px;
                    background-color: #ffb6a6;
                    position: absolute;
                    bottom: -16px;
                    left: 20px;
                }
            }
            .content-blog-home{
                background-color: #fffde5;
                min-height: 230px;
                border-radius: 0px 0px 24px 24px;
                padding: 40px 20px;
                .date{
                    font-size: 13px;
                    line-height: 2px;
                    color: #004d72;
                    font-weight: 500;
                    opacity: 0.702;
                    display: block;
                    padding-bottom: 20px;
                    padding-top: 10px;
                }
                .title{
                    h3,a{
                        font-size: 17px;
                        line-height: 27px;
                        color: #054c72;
                        font-weight: 700;
                        text-decoration: none;
                    }
                }
            }
        }
        
    }
}

.section-recent-posts{
    padding: 60px 0px;
    h2{
        font-size: 35px;
        line-height: 42px;
        color: #004e72;
        font-weight: 400;
        padding: 20px 0px;
        strong{
            font-weight: bold;
        }
    }
    .post-left{
        .post-item{
            margin-bottom: 15px;
            picture{
                position: relative;
                img{
                    max-width: 100%;
                    border-radius: 18px;
                }
            }
            .category{
                display: inline-block;
                padding: 6px 15px;
                font-size: 13px;
                line-height: 16px;
                color: #014f73;
                font-weight: 500;
                border-radius: 8px;
                background-color: #ffc700;
                margin-top: 20px;
            }
            .content{
                padding-bottom: 20px;
                h3{
                    font-size: 17px;
                    line-height: 29px;
                    color: #054c72;
                    font-weight: 700;
                    padding: 20px 0px 10px 0px;
                    a{
                        color: #054c72;
                        text-decoration: none;
                    }
                }
                .summary{
                    font-size: 15px;
                    line-height: 24px;
                    color: #054c72;
                    font-weight: 400;
                    padding: 10px 0px 10px 0px;
                }
                .time{
                    font-size: 13px;
                    line-height: 2px;
                    color: #004e72;
                    font-weight: 500;
                    padding: 10px 0px 10px 0px;
                    .fa{
                        color: #ffc700;
                        margin-right: 7px;
                    }
                }
            }
        }
    }

    .post-right{
        .post-item{
            position: relative;
            margin-bottom: 20px;
            picture{
                width: 100%;
                height: 240px;
                display: block;
                border-radius: 18px 18px 0px 0px;
                position: relative;
                background-repeat: no-repeat;
                background-size: cover;
                img{
                    max-width: 100%;
                }
            }
            .category{
                display: inline-block;
                padding: 6px 15px;
                font-size: 13px;
                line-height: 16px;
                color: #014f73;
                font-weight: 500;
                border-radius: 8px;
                background-color: #ffc700;
                position: absolute;
                top: 225px;
                left: 20px;
            }
            .content{
                background-color: #fffde5;
                height: 220px;
                border-radius: 0px 0px 18px 18px;
                padding: 25px;
                h3{
                    font-size: 17px;
                    line-height: 29px;
                    color: #054c72;
                    font-weight: 700;
                    padding: 10px 0px 10px 0px;
                    a{
                        color: #054c72;
                        text-decoration: none;
                    }
                }
                .time{
                    font-size: 13px;
                    line-height: 2px;
                    color: #004e72;
                    font-weight: 500;
                    padding: 10px 0px 10px 0px;
                    .fa{
                        color: #ffc700;
                        margin-right: 7px;
                    }
                }
            }
            
        }
    }
}

.section-newsletter-light{
    padding: 75px 0px;
    background-color: #FFFFFF;
    .title{
        padding-left: 95px;
        height: 78px;
        background-image: url(/assets/images/ico-newsletter2.webp);
        background-repeat: no-repeat;
        h3{
            font-size: 35px;
            line-height: 42px;
            color: #004d72;
            font-weight: 900;
            strong{
                font-weight: bold;
            }
        }
        span{
            font-size: 15px;
            line-height: 23px;
            color: #004d72;
            font-weight: 400;
        }
    }
    form{
        margin-top: 30px;
        width: 100%;
        .form-control{
            padding: 12px 20px;
            font-size: 15px;
            line-height: 27px;
            color: #004d72;
            font-weight: 400;
            background-color: #ebebeb;
            &::placeholder{
                color: #004d72;
            }
        }
        button[type="submit"]{
            border-radius: 8px;
            background-color: #014f73;
            padding: 16px 20px;
            font-size: 17px;
            line-height: 20px;
            color: #fffde5;
            font-weight: 700;
        }
    }
}

.section-newsletter{
    padding: 75px 0px;
    background-color: #0178b1;
    background-image: url(/assets/images/bg_blog_zarpar.webp);
    background-repeat: no-repeat;
    background-position: top right;
    @media  (max-width: 767px) {
        background-image: none;
    }
    .title{
        padding-left: 95px;
        height: 78px;
        background-image: url(/assets/images/ico-newsletter.webp);
        background-repeat: no-repeat;
        h3{
            font-size: 35px;
            line-height: 42px;
            color: #ffffff;
            font-weight: 900;
            strong{
                font-weight: bold;
            }
        }
        span{
            font-size: 15px;
            line-height: 23px;
            color: #ffffff;
            font-weight: 400;
        }
    }
    form{
        margin-top: 30px;
        width: 100%;
        .form-control{
            padding: 12px 20px;
            font-size: 15px;
            line-height: 27px;
            color: #004d72;
            font-weight: 400;
            &::placeholder{
                color: #004d72;
            }
        }
        button[type="submit"]{
            border-radius: 8px;
            background-color: #014f73;
            padding: 16px 20px;
            font-size: 17px;
            line-height: 20px;
            color: #fffde5;
            font-weight: 700;
        }
    }
}

.section-details{
    padding: 60px 0px;
    border-top: 1px solid #ebebeb;
    color: #054c72;
    .bread-crumbs{
        display: inline-block;
        padding: 0px;
        list-style: none;
        li{
            display: inline-block;
            a{
                text-decoration: none;
                font-size: 15px;
                line-height: 24px;
                color: #054c72;
                font-weight: 400;
                padding-left: 10px;
                padding-right: 10px;
            }
            &:not(:last-child)::after{
                content: '›';
                color: #ffc700;
            }
        }
    }
    .blog-post-header-info{
        font-size: 13px;
        line-height: 21px;
        color: #054c72;
        font-weight: 400;
        padding-top: 40px;
        padding-bottom: 40px;
        a{
            color: #ffa566;
            font-weight: 700;
            text-decoration: none;
        }
    }
    .blog-post-body-info{
        font-size: 15px;
        line-height: 27px;
        color: #004d72;
        font-weight: 400;
        a{
            color: #ffa566;
            text-decoration: none;
        }
        h1{
            font-size: 35px;
            line-height: 42px;
            color: #004d72;
            font-weight: 900;
            padding-top: 15px;
            padding-bottom: 15px;
            display: table;
            position: relative;
            &::before{
                content: "";
                display: block;
                position: absolute;
                width: 35px;
                height: 7px;
                background-color: #ffc700;
                border-radius: 5px;
                top: -18px;
                transform: rotate(-32deg);
            }
        }
        h2{
            font-weight: 900;
            font-size: 25px;
            line-height: 30px;
        }
        picture{
            position: relative;
            img {
                max-width: 580px;
                width: 100%;
                border-radius: 18px;
                float: left;
                margin-right: 15px;
                margin-bottom: 15px;
            }
            padding: 15px;
        }
    }
    input[type="search"]{
        padding: 12px 20px;
        border-radius: 14px;
        background-color: #014f73;
        color: #FFFFFF;
        &::placeholder{
            color: #FFFFFF;
        }
    }
    .blog-post-social{
        padding: 60px 0px;
        a{
            text-decoration: none;
        }
    }
}

.section-contact-form{
    padding: 60px 0px;
    font-size: 19px;
    line-height: 38px;
    color: #fffde5;
    font-weight: 400;
    background-color: #014f73;
    background-image: url(/assets/images/bg-contact.webp);
    background-repeat: no-repeat;
    background-position: top -10px center;
    @media  (max-width: 991px) {
        background-image: none;
    }
    .title{
        padding-top: 60px;
        h2{
            font-size: 45px;
            line-height: 72px;
            color: #ffffff;
            font-weight: 900;
            position: relative;
            max-width: 400px;
            &::before{
                content: "";
                display: block;
                position: absolute;
                width: 35px;
                height: 7px;
                background-color: #ffc700;
                border-radius: 5px;
                top: -18px;
                transform: rotate(-32deg);
            }
        }
    }
    .call-to-whatsapp{
        font-size: 15px;
        line-height: 21px;
        color: #ffffff;
        font-weight: 400;
        text-decoration: none;
        .fa{
            width: 50px;
            height: 50px;
            border-radius: 25px;
            background-color: #03b654;
            text-align: center;
            color: #FFFFFF;
            font-size: 25px;
            line-height: 50px;
            margin-right: 10px;
        }
    }

    form{
        max-width: 405px;
        width: inherit;
        float: right;
        @media  (max-width: 767px) {
            float: none;
        }
        .box-accept-news{
            padding: 20px 30px;
            background-color: #fffde5;
            border-radius: 0px 0px 24px 24px;
            font-size: 13px;
            line-height: 25px;
            color: #014f73;
            font-weight: 400;
            input[type="checkbox"]{
                width: 20px;
                height: 20px;
                border-radius: 4px;
                border: 1px solid #e0e0e0;
                margin-right: 10px;
            }
        }
        .box-form{
            padding: 30px 30px 0px 30px;
            background-color: #FFFFFF;
            border-radius: 24px 24px 0px 0px;
            h3{
                font-size: 35px;
                line-height: 39px;
                color: #004e72;
                font-weight: 900;
            }
            .form-control, .form-select{
                border-radius: 14px;
                background-color: #ebebeb;
                height: 50px;
                border: none;
                font-size: 13px;
                line-height: 23px;
                color: #014f73;
                font-weight: 400;
                &::placeholder{
                    font-size: 13px;
                    line-height: 23px;
                    color: #014f73ad;
                    font-weight: 400;
                }
            }
            textarea{
                border-radius: 14px;
                background-color: #ebebeb;
                height: 90px;
                width: 100%;
                border: none;
                padding: 0.375rem 2.25rem 0.375rem 0.75rem;
                font-size: 13px;
                line-height: 23px;
                color: #014f73;
                font-weight: 400;
                &::placeholder{
                    font-size: 13px;
                    line-height: 23px;
                    color: #014f73ad;
                    font-weight: 400;
                }
            }
            button[type="submit"]{
                height: 90px;
                border-radius: 14px;
                background-color: #ffa566;
                text-align: center;
                font-size: 15px;
                line-height: 18px;
                color: #fffde5;
                font-weight: 700;
                width: 100%;
                border: none;
            }
        }
    }
}

.section_page{
    padding: 60px 0px;
    font-size: 15px;
    line-height: 24px;
    color: #054c72;
    font-weight: 400;
    border-top: 1px solid #ebebeb;
    .title{
        padding-bottom: 30px;
        h1{
            font-size: 35px;
            line-height: 60px;
            color: #054c72;
            font-weight: 700;
            position: relative;
            &::before{
                content: "";
                display: block;
                position: absolute;
                width: 35px;
                height: 7px;
                background-color: #ffc700;
                border-radius: 5px;
                top: -18px;
                transform: rotate(-32deg);
            }
        }
        span{
            font-size: 13px;
            line-height: 21px;
            color: #054c72e1;
            font-weight: 400;
            font-style: italic;
        }
    }
}

.section-pre-pago{
    padding: 60px 0px;
    background-color: #0178b1;
    position: relative;
    font-size: 15px;
    line-height: 24px;
    color: #ffffff;
    font-weight: 400;
    h1{
        font-size: 40px;
        line-height: 48px;
        color: #ffffff;
        font-weight: 400;
        strong{
            font-weight: 900;
        }
    }
}

.section-banner-pre-pago{
    background-color: #0178b1;
    padding-top: 10px;
    .banner-pre-pago{
        .banner-pre-pago-item{
            min-height: 520px;
            background-repeat: no-repeat;
            background-size: 100% 520px;
            padding-right: 53%;
            padding-left: 25px;
            padding-top: 150px;
            @media  (max-width: 540px) {
                padding-top: 100px;
            }
            @media  (max-width: 1399px) {
                background-size: auto 520px;
            }
            @media  (max-width: 991px) {
                padding-right: 30%;
            }
            @media  (max-width: 767px) {
                padding-right: 25px;
            }
            h2{
                font-size: 35px;
                @media  (max-width: 540px) {
                    font-size: 30px;
                }
                line-height: 56px;
                color: #fffde5;
                font-weight: 400;
                position: relative;
                padding-top: 15px;
                strong{
                    font-weight: bold;
                }
                &::before{
                    content: "";
                    display: block;
                    position: absolute;
                    width: 35px;
                    height: 7px;
                    background-color: #ffc700;
                    border-radius: 5px;
                    top: -18px;
                    transform: rotate(-32deg);
                }
            }
        }
    }
    
}